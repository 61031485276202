.nftCard .tranding-img {
  height: 200px;
}
.nftCard .tranding-img img {
  height: 100%;
  width: 100;
}
.nftCard .fs-small {
  font-size: 14px;
}
.nftCard .tranding-box {
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nftCard .viewAll {
  color: #0052ff;
  text-decoration: underline !important;
  font-size: 14px;
}
.nftCard .buyButton {
  border-radius: 8px;
}