.bg-theme{
    background-color: #f8fafe;
}

.navbar .common-btn{
    height: auto;
    width: auto;
    border-radius: 4px;
}

.register-btn{
    background-color: transparent;
    border: 1px solid #185CE3;
    color: #185ce3;
}