.auth-card {
  position: relative;
  /* margin-top: -152px; */
}
.kyc-block {
  max-width: 430px;
  margin: auto;
  padding: 0px 20px 20px 20px;
}

.form-check-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.019em;
  color: rgba(158, 158, 158, 1);
  margin-right: 0 !important;
}

label{
    cursor: pointer;
}

.top-shapes {
    width: 330px;
    margin: auto;
}

.common-btn{
    z-index: 1;
    position: relative;
    overflow: hidden;
}

/* .common-btn::before,.common-btn::after{
    content: "";
    position: absolute;
    width: 80px;
    height: 65px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

.common-btn::before{
    background-image: url(../../images/shape.svg);
    right: -40px;
    transform: rotate(90deg);
    bottom: -50px;
}

.common-btn::after{
    background-image: url(../../images/shape2.svg);
    left: -40px;
    bottom: -20px;

} */

