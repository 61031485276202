.fredCard .tranding-img {
  height: 200px;
}
.fredCard .tranding-img img {
  height: 100%;
  width: 100;
}
.fredCard .fs-small {
  font-size: 14px;
}
.fredCard .tranding-box {
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fredCard .viewAll {
  color: #0052ff;
  text-decoration: underline !important;
  font-size: 14px;
}
.fredCard .buyButton {
  border-radius: 8px;
}