.user-droupdown {
  top: 85%;
  right: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #00000040;
  width: 400px;
  max-width: 100%;
  padding: 24px;
  border-radius: 8px;
  z-index: 9;
}
.notification{
  border: 1px solid #8080808a;
    padding: 10px;
    border-radius: 8px
}
.f-18 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
}
.nav-right{
  display: flex;
  gap: 8px;
}
.notification-icon{
  width: 45px;
  border: 1px solid gray;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.edit-btn {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(0deg, #222222, #222222),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  border-radius: 50px;
  color: #fff;
}

.user-option li {
  display: block;
}

.user-option li:not(:last-child){
    margin-bottom: 20px;
}

.user-option li a,.user-option li button {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.user-option li.logout a{
     color: #E80000;
}
