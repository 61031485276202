.posse-content h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #121212;
}
.posse-content .f-14 {
  font-weight: 700;
  color: #121212;
  font-weight: 500;
}

.posse-content .f-14 strong {
  font-weight: 700;
  color: #1e9f14;
}

.posse-img img{
    width: 100%;
}

.posse-card{
    box-shadow: 0px 2px 10px 0px #00000040;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
}

.property-fee {
    bottom: 0;
    width: 100%;
}