.solos-card{
    box-shadow: 1.4718068838119507px 2.2077105045318604px 7.359034538269043px 0px #00000026;
    background: url(../../images/blackc.svg) no-repeat center center;
    background-size: cover;
    border-radius: 9px;
    padding: 30px;
    min-height: 285px;
}

.solos-card .token-btn{
    width: 45px;
    height: 45px;
    border-radius: 50px;
    background-color: #fff;
}
.solos-card .fs-8{
    font-size: 14px;
}

@media(max-width: 1399.98px){
    .red-token, .solos-card, .booq-card {
        min-height: 240px !important;
        margin-bottom: 20px;
    }
}

@media(max-width: 767.98px){
    .token-btn {
        width: 30px !important;
        height: 30px !important;
    }
    .token-bottom button {
        font-size: 10px !important;
    }
    .solos-card .fs-8 {
        font-size: 10px !important;
    }
}