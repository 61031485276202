.superFredCard .tranding-img {
    height: 200px;
  }
  .superFredCard .tranding-img img {
    height: 100%;
    width: 100;
  }
  .superFredCard .fs-small {
    font-size: 14px;
  }
  .superFredCard .tranding-box {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .superFredCard .viewAll {
    color: #0052ff;
    text-decoration: underline !important;
    font-size: 14px;
  }
  .superFredCard .buyButton {
    border-radius: 8px;
  }