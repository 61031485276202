.qr-info {
  background: #005cee26;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #005cee;
}

.qr-info i {
    width: 40px;
    display: inline-block;
    text-align: center;
}

.qr-info i svg{
    font-size: 24px; 
}

.back-btn i svg{
    color: #00000080;
}
.back-btn{
    display: flex;
    cursor: pointer;
    margin-left: 20px;
}

.user-form Form.Control::placeholder{
    color: red;
}
