h1{
    color: #0E1B3C;
}

.quotes {
    position: absolute;
    top: 0;
    right: -30px;
}

.relative{
    position: relative !important;
}