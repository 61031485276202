.faq-wrapper {
  padding: 150px 0;
  background-color: #fff;
}

.faq-wrapper .accordion-item {
  border-radius: 16px;
  overflow: hidden;
}

.faq-wrapper .accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
}

.faq-wrapper .accordion-item .accordion-button {
  background: linear-gradient(
    95.58deg,
    rgba(0, 0, 0, 0.24) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  width: 100% !important;
  color: #00000050;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 24px;
}

.faq-wrapper .accordion-item .accordion-button:not(.collapsed) {
  background-color: #185ce3;
  box-shadow: none;
  color: #fff;
}

.faq-wrapper .accordion-item .accordion-button::after{
  background-image: url(../../../images/plus.svg);
  background-size: 40px;
  --bs-accordion-btn-icon-width:40px;
  transform: none;
}

.faq-wrapper .accordion-item .accordion-button:not(.collapsed)::after{
  background-image: url(../../../images/minus.svg);
}

.common-btn.radius-4 {
    border-radius: 4px;
    width: max-content;
    margin: auto;
}