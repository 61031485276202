.filter-tabs button{
    padding: 5px 40px;
    border-radius: 20px;
}
.transaction-navs .nav-link{
    color: #000;
    width: 100px;
}

.transaction-navs .nav-link.active{
    color: #0052ff;
    border: none;
    border-bottom: 2px solid #0052ff !important;
    background: transparent;
}
.transaction-navs .nav-link:hover{
    border: none;
}