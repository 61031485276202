h3.f-16 {
  color: #0e1b3c;
}

ul li:not(:last-child) {
  /* margin-bottom: 10px; */
}

ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #525d7a;
}
