.red-token{
    box-shadow: 1.4718068838119507px 2.2077105045318604px 7.359034538269043px 0px #00000026;
    background: url(../../images/bluec.svg) no-repeat center center;
    background-size: cover;
    border-radius: 9px;
    padding: 30px;
    min-height: 285px;
}

.red-token .token-btn{
    width: 45px;
    height: 45px;
    border-radius: 50px;
    background-color: #fff;
}