/* .nftDetail{
    color: #000;
} */
.nftDetail .sideImage .imageFigure{
    height: 350px;
    width: 100%;
    overflow: hidden;
    background-color: #000 !important ;
    border-radius: 10px;
}
.nftDetail .sideImage img{
    width: 100%;
    object-fit: contain;
    height: 100%;
    background: #cce7ff ;
    border-radius: 8px;
}
.nftDetail .mintNowText{
    border: 1px solid gray;
    border-radius: 12px;
}
.nftDetail .mintButton button{
    color: #fff;
    background-color: #0052ff;
    border-radius: 8px;
}