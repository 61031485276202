.tvtCard .tranding-img {
    height: 200px;
  }
  .tvtCard .tranding-img img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .tvtCard .fs-small {
    font-size: 14px;
  }
  .tvtCard .tranding-box {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tvtCard .viewAll {
    color: #0052ff;
    text-decoration: underline !important;
    font-size: 14px;
  }
  .tvtCard .buyButton {
    border-radius: 8px;
  }